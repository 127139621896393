<template>
    <div>
      <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#4480C5"
      size="60"
      is-full-screen
      />
      <ServerError v-if="ServerError" />
      <v-snackbar v-model="showSnackBar" color="primary" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap justify-center py-16>
        <v-flex xs10 sm8 md6 lg5 xl4>
          <v-card outlined class="pa-5">
            <v-card-title class="passwordhead text-center pb-4">
              Change Password
            </v-card-title>
            <v-card-text>
              <v-layout wrap class="live-des">
                <v-flex xs12 text-left>
                    <span class="itemValue">Current Password</span>
                    <v-text-field
                      ref="password"
                      color="primary"
                      
                      :type="currentPassword ? 'text' : 'password'"
                      dense
                      v-model="oldPassword"
                      class="mb-4"
                    >
                      <template v-slot:append>
                        <v-icon @click="togglePasswordVisibility1" class="password-icon">
                          {{ currentPassword ? 'mdi-eye' : 'mdi-eye-off' }}
                        </v-icon>
                      </template>
                    </v-text-field>
                  </v-flex>
                  
                  <v-flex xs12 text-left>
                    <span class="itemValue">New Password</span>
                    <v-text-field
                      ref="newpassword"
                      color="primary"
                   
                      :type="newPasswordVisible ? 'text' : 'password'"
                      dense
                      v-model="newPassword"
                      class="mb-4"
                    >
                      <template v-slot:append>
                        <v-icon @click="togglePasswordVisibility2" class="password-icon">
                          {{ newPasswordVisible ? 'mdi-eye' : 'mdi-eye-off' }}
                        </v-icon>
                      </template>
                    </v-text-field>
                  </v-flex>
                  
                  <v-flex xs12 text-left>
                    <span class="itemValue">Confirm New Password</span>
                    <v-text-field
                      ref="newcpassword"
                      color="primary"
                     
                      :type="confPasswordVisible ? 'text' : 'password'"
                      dense
                      v-model="confirmPassword"
                      class="mb-4"
                    >
                      <template v-slot:append>
                        <v-icon @click="togglePasswordVisibility3" class="password-icon">
                          {{ confPasswordVisible ? 'mdi-eye' : 'mdi-eye-off' }}
                        </v-icon>
                      </template>
                    </v-text-field>
                  </v-flex>
                  
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-layout wrap justify-center >
                <v-flex xs12 sm6 class="pa-2">
                  <v-btn 
                    block
                    tile
                    outlined
                    color="primary"
                    light
                    :ripple="false"
                    depressed
                 
                    
                   
                  ><span class="passwordbtn">
                    Cancel
                  </span>
                    
                  </v-btn>
                </v-flex>
                <v-flex xs12 sm6 class="pa-2">
                  <v-btn
                    block
                    tile
                    color="primary"
                    light
                    :ripple="false"
                    depressed
                    @click="validateInput"
                    
                    
                  ><span class="passwordbtn">
                    Change Password</span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
      
    </div>
  </template>
  <script>
  import axios from "axios";
  export default {
    components: {},
    data() {
      return {
        appLoading: false,
        ServerError: false,
        showSnackBar: false,
        timeout: 5000,
        msg: null,
        oldPassword: null,
      newPassword: null,
      confirmPassword: null,
      currentPassword: false,
      newPasswordVisible: false,
      confPasswordVisible: false,
      };
    },
    methods: {
    //   closeDialoge() {
    //     this.$emit("stepper", {
    //       type: "Password",
    //       getData: false,
    //       changePassword: false,
    //       status: false,
    //     });
    //   },
    togglePasswordVisibility1() {
      this.currentPassword = !this.currentPassword;
    },
    togglePasswordVisibility2() {
      this.newPasswordVisible = !this.newPasswordVisible;
    },
    togglePasswordVisibility3() {
      this.confPasswordVisible = !this.confPasswordVisible;
    },
  
  validateInput() {
    if (!this.oldPassword) {
      this.msg = "Please Provide Current Password";
      this.showSnackBar = true;
      return;
    } else if (!this.newPassword) {
      this.msg = "Please Provide New Password";
      this.showSnackBar = true;
      return;
    } else if (!this.confirmPassword) {
      this.msg = "Please Provide Confirm Password";
      this.showSnackBar = true;
      return;
    } else if (this.newPassword !== this.confirmPassword) {
      this.msg = "New Password and Confirm Password do not match";
      this.showSnackBar = true;
      return;
    }
     else if (!this.validatePassword(this.newPassword)) {
      this.msg = "Password must be at least 8 characters, contain at least one uppercase letter, one lowercase letter, one number, and one special character.";
      this.showSnackBar = true;
      return;
    }
     else {
      this.changePassword();
    }
  },

  validatePassword(password) {
    // Regular expression to check the password criteria
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
    return passwordRegex.test(password);
  },

    //   changePassword() {
    //     var userData = {};
    //     userData["password"] = this.password;
    //     userData["oldpassword"] = this.oldPassword;
    //     axios({
    //       method: "post",
    //       url: "/user/Edit",
    //       headers: {
    //         "x-auth-token": localStorage.getItem("token"),
    //       },
    //       data: userData,
    //     })
    //       .then((response) => {
    //         this.appLoading = false;
    //         if (response.data.status) {
    //           this.$emit("stepper", {
    //             type: "Password",
    //             getData: false,
    //             changePassword: false,
    //             status: true,
    //           });
    //         } else {
    //           this.msg = response.data.msg;
    //           this.showSnackBar = true;
    //         }
    //       })
    //       .catch((err) => {
    //         this.appLoading = false;
    //         console.log(err);
    //       });
    //   },

      
      changePassword() {
          var data = {};
          data["oldPassword"] = this.oldPassword;
          data["newPassword"] = this.newPassword;
          data["confirmPassword"] = this.confirmPassword;
          axios({
            url: "/user/resetpassword",
            method: "POST",
            data: data,
            headers: {
              token: localStorage.getItem("token"),
            },
          })
            .then((response) => {
              this.appLoading = false;
              if (response.data.status) {
                this.msg = response.data.msg;
            this.showSnackBar = true;
                this.dialog = false;
                location.reload();

              } 
              else {
                this.msg = response.data.msg;
            this.showSnackBar = true;
          
              }
            })
            .catch((err) => {
              this.appLoading = false;
              this.ServerError = true;
              console.log(err);
            });
        },
      
    },
  };
  </script>